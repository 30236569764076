<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>   
            Воспитатели
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-success" @click="create()">
              {{ $t("common.new") }}
            </button>
            <b-button @click="exportTeachers">Экспорт</b-button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("teachersList.inn") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.firstName") }}
                </th>
                <th class="text-left">{{ $t("teachersList.name") }}</th>
                <th class="text-left">
                  {{ $t("teachersList.parentName") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.contactPhone") }}
                </th>
                <th class="text-left">{{ $t("teachersList.userName") }}</th>
                <th class="text-left">{{ $t("teachersList.password") }}</th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in kindergarteners" :key="item.id">
                <td>{{ item.inn }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.patronymic }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>{{ item.userName }}</td>
                <td>{{ item.password }}</td>
                <td>
                  <b-button
                      type="submit"
                      @click="editTeacher(item)"
                      variant="primary"
                  >
                    {{ $t("common.edit") }}
                  </b-button>

                  <b-button
                      v-if="item.isDeletable == true"
                      @click="remove(item)"
                      variant="danger"
                  >
                    Удалить
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <b-modal id="modal-1" ref="kindergartener-modal">
              <template v-slot:modal-title>
                {{ $t("common.new") }}
              </template>
              <b-form v-if="show">
                <b-form-group id="input-group-1" label-for="input-1">
                  <template v-slot:label>
                    {{ $t("teachersList.inn") }}
                  </template>
                  <b-form-input
                      id="input-1"
                      v-model="form.inn"
                      type="email"
                      :state="teacherModelStates.inn"
                      required
                  ></b-form-input>
                  <div class="invalid-feedback">{{teacherModelSaveError.inn}}</div>
                </b-form-group>
                <b-form-group id="input-group-1" label-for="input-1">
                  <template v-slot:label>
                    {{ $t("teachersList.firstName") }}
                  </template>
                  <b-form-input
                      id="input-1"
                      v-model="form.firstName"
                      type="email"
                      :state="teacherModelStates.firstName"
                      required
                  ></b-form-input>
                  <div class="invalid-feedback">{{teacherModelSaveError.firstName}}</div>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="input-2">
                  <template v-slot:label>
                    {{ $t("teachersList.name") }}
                  </template>
                  <b-form-input
                      id="input-2"
                      v-model="form.name"
                      :state="teacherModelStates.name"
                      required
                  ></b-form-input>
                  <div class="invalid-feedback">{{teacherModelSaveError.name}}</div>
                </b-form-group>

                <b-form-group id="input-group-3" label-for="input-3">
                  <template v-slot:label>
                    {{ $t("teachersList.parentName") }}
                  </template>
                  <b-form-input
                      id="input-3"
                      v-model="form.patronymic"
                      :state="teacherModelStates.patronymic"
                      required
                  ></b-form-input>
                  <div class="invalid-feedback">{{teacherModelSaveError.patronymic}}</div>
                </b-form-group>
                <b-form-group id="teacherBd-g" label-for="teacherBd">
                  <template v-slot:label>
                    {{ $t("teachersList.birthDate") }}
                  </template>
                  <b-form-datepicker
                      id="teacherBd"
                      v-model="form.birthDate"
                      required
                  ></b-form-datepicker>
                </b-form-group>
                <b-form-group id="input-group-4" label-for="input-4">
                  <template v-slot:label>
                    {{ $t("teachersList.contactPhone") }}
                  </template>
                  <b-form-input
                      id="input-4"
                      v-model="form.contactPhone"
                      :state="teacherModelStates.contactPhone"
                      required
                  ></b-form-input>
                  <div class="invalid-feedback">{{teacherModelSaveError.contactPhone}}</div>
                </b-form-group>
              </b-form>
              <template v-slot:modal-footer>
                <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "PreschoolAdminKindergartener",
  components: {  
    KTPortlet
  },
  data() {
    return {
      preschoolId: null,
      kindergarteners:[],
      dialog: true,
      form: {
        inn: "",
        firstName: "",
        name: "",
        parentName: "",
        contactPhone: "",
        birthDate: ""
      },
      show: true,
      teacherModelStates:{},
      teacherModelSaveError:{},
    };
  },
  mounted() {
   this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.preschoolId = parseInt(this.$route.params.id);

      ApiService.postSecured("kindergartener/save", this.form)
          .then(function() {
            $this.$refs["kindergartener-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({ response }) => {
            if(response.data != null && response.data.errors != null){
              $this.teacherModelSaveError = response.data.errors;

              for (let i in response.data.errors){

                $this.teacherModelStates[i] = false;
              }
            }
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["kindergartener-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("kindergartener/list", {
        params: { schoolId: this.$route.params.id }
      })
          .then(({ data }) => {
            this.kindergarteners = data.list;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    remove(teacher){
      let $this = this;
      if(confirm("Удалить воcпитателя?")){
        ApiService.postSecured("kindergartener/delete", {id:teacher.id})
            .then(function () {

              $this.reloadData();
            })
            .catch(() => {

            }).finally(function () {

        });
      }
    },
    create(){
      this.form = {};
      this.$refs["kindergartener-modal"].show();
    },
    cleanModel() {
      this.form = {};
      this.teacherModelSaveError = {};
      this.teacherModelStates = {};
    },
    editTeacher(kindergartener) {
      this.form = kindergartener;
      this.$refs["kindergartener-modal"].show();
    },
    exportTeachers(){

      ApiService.downloadSecured("kindergartener/exportToExcel")
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Воститатели пароли.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
  }
};
</script>
